@font-face {
  font-family: "SF Pro Text";
  src: url(./fonts/FontsFree-Net-SFProText-Regular-1.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 500;
  src: url(./fonts/FontsFree-Net-SFProText-Medium-1.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 600;
  src: url(./fonts/FontsFree-Net-SFProText-Bold-1.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display";
  src: url(./fonts/FontsFree-Net-SFProDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display";
  font-weight: 600;
  src: url(./fonts/FontsFree-Net-SFProDisplay-Bold.ttf) format("truetype");
}


@font-face {
  font-family: "Metropolis";
  src: url(./fonts/Metropolis-Regular.ttf) format('opentype');
}

@font-face {
  font-family: "Metropolis";
  font-weight: 700;
  src: url(./fonts/Metropolis-Bold.ttf) format('opentype');
}

@font-face {
  font-family: "Metropolis";
  font-weight: 900;
  src: url(./fonts/Metropolis-ExtraBold.ttf) format('opentype');
}

@media only screen and (min-width: 481px) {
  body {
    scrollbar-gutter: stable both-edges;
  }

  body::-webkit-scrollbar {
    width: 1em;
    background-color: transparent;
  }

  body::-webkit-scrollbar-thumb {
    background: #C1C1C1;
    height: 30px;
  }

  body::-webkit-scrollbar-track-piece {
    display: none;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    zoom: 70%;

  }
}


body,
html {
  margin: 0;
  font-family: "SF Pro Text", sans-serif, "Metropolis", "SF Pro Display";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9FBFC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}