.container {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
}

.form-container {
	padding: 30px 20px 20px 20px;
	width: 100%;
	max-width: 860px;
}