.container {
  background-color: white;
  overflow: hidden;
  width: 100%;
  transition: all 1s ease-in-out;
  display: flex;
  align-items: flex-end;
}

.inner-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
}

.content {
  flex: 1;
  overflow: hidden;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
  margin-left: 10px;
}

.release-note-content-title {
  font-weight: 700;
}

.message-container {
  color: white;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}