.list-container {

}
.list-container::-webkit-scrollbar{
	width: 4px;
	height: 4px;
}
.list-container::-webkit-scrollbar-thumb{
	background:#FF6D1D;
    border-radius:5px;

}
.list-container::-webkit-scrollbar-track {
    border-radius: 8px;
    background: #ECE9F1;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row:not(:last-child) {
  margin-bottom: 10px;
}
.header-row {
  padding: 0 5px;
}

.item-row {
  border-radius: 5px;
  padding: 10px 5px;
}

.item {
  padding: 0 5px;
}

.empty-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-container,
.select-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-container {
  cursor: pointer;
}

.secondary-data {
  margin-top: 5px;
}

.colorful {
  font-weight: 700;
}
.colorful.positive {
  color: #43B868;
}
.colorful.negative {
  color: #FF0000;
}


.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  height:25px;
  width: 50%;
  border-radius: 10px;
  font-weight: 700;
}
.tile1{
  background-color: #ADEFC2;
  color: #43B868;
}
.tile3{
  background-color: rgba(255, 0, 0, 0.3);
  color:#E40A0A;
}
.tile2{
  background-color: rgba(254, 198, 92, 0.3);
  color:#FFB221;
}