.form-container {
	/* padding: 0px 70px 0 70px; */
    position: "relative";
    width: 100%;
    display: "flex";
    justify-content: center;
   
}

.button-container {
	position: relative;
	display: flex;
	margin-top: 48px;
	width: 100%;
	text-align: center;
    justify-content: center;
}
