.cell-wrap {
  white-space: pre-wrap;
  overflow: hidden;
}

.truncate {
  white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table-container {
  width: 100%;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table td {
  border-left: .5px solid #B9B9C3;
  border-right: .5px solid #B9B9C3;
}
.table td:first-child {
  border-right: none;
}
.table td:last-child {
  border-left: none;
}

.table td,
.table th {
  text-align: center;
}
.table td:first-child,
.table th:first-child {
  text-align: left;
  padding: 0 30px;
}

th.data-column {
  width: 15%;
}

.table > tbody > tr {
  border: none;
  height: 30px;
}

.row-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

table > tbody > tr:last-child {
  border-bottom: .5px solid #B9B9C3;
}

table > tbody > tr > td {
  padding: .75rem 0;
  margin: 0 auto;
}

.row-title-span {
  font-weight: 700;
  margin-right: 5px;
}

.cell-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
.table > thead > tr {
  border-bottom: 50px solid transparent;
}

.table > tbody > tr {
  border: none;
  height: 100px !important;
}
*/


/*
th.title-column {
}
.even-row {
}
.odd-row {
}
.table > tbody > tr > td:first-child {
}
.table > tbody > tr > td:not(:first-child) {
}

.table-column {

}
.table-column.subtle {
}

.table-head {
}

table > thead > tr > th {
}

.row-disabled {
}
*/