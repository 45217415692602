.container {
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 12px;
}

.container > label {
	margin-bottom: 5px;
	display: flex;
	font-style: normal;
	line-height: 20px;
	color: #000000
}

.container > textarea {
  border-radius: 4px;
  box-sizing: border-box;
  color: #1a051d;
  font-style: normal;
  font-weight: normal;
  padding: 15px; 
}
.container > textarea:focus {
  outline: none;
  border-color: #FF6D1D;
}
.container > textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #d0c9d6;
}

.read-only-span {
  border-radius: 4px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  padding: 15px;
}