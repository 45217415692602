.loading-container {
	width: 100%;
	height: 100%;
	min-height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* .reference-container {
	position: relative;
	display: flex;
	margin-top: 0px;
	margin-left: -70px;
	margin-right: 0px;
	width:120%;
} */

.container {
	width: 100%;
	/*height: 100%;*/
	min-height: 250px;
	line-height: normal;
}

.title-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px 70px 20px;
}
.title-text {
	font-weight: 700;
}

.navigation-container {

}

.navigation-inner-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navigation-element-container {
	cursor: pointer;
}
.navigation-icon-container {
	height: 56px;
	width: 56px;
}
.navigation-text-container {
	height: 48px;
	width: 48px;
	padding: 5px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.navigation-element-container:not(:last-child) {
	margin-right: 40px;
}
.navigation-text-container > span {
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.navigation-tab-box {
	width: 100%;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #DAE0E7;
	background: #F9F9F9;
	border-radius: 8px 8px 0 0;
	transition: all .1s;
	cursor: pointer;
	user-select: none;
}
.navigation-tab-box:hover {
	background: #fff;
}
.navigation-tab-box.selected-tab {
	border-bottom: 1px solid #F9FBFC;;
	background: #F9FBFC;
	color: #FF6D1D;
	font-weight: 700;
}
.tab-error {
	justify-content: space-between !important;
}


.navigation-tab-box > span {
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.page-container {
 
}
.page-scroll-container{
  
}
.page-scroll-container::-webkit-scrollbar{
	width: 4px;
	height: 4px;
}
.page-scroll-container::-webkit-scrollbar-thumb{
	background:#FF6D1D;
    border-radius:5px;

}
.page-scroll-container::-webkit-scrollbar-track {
    border-radius: 8px;
    background: #ECE9F1;
}
.page-title-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
}
.page-title-text {
	font-weight: 700;
}

.page-content-container {
	margin-top: 32px !important;
}

.item-container {
	margin-top: 16px;
}
.item-group-container {
	width: 100%;
	margin-top: 16px;
	display: flex;
	align-items: center;
}
.item-group-container > .item-inner-container:not(:last-child) {
	margin-right: 10px;
}

.item-inner-container {
}

.year-group-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.year-group-container > div:not(:last-child) {
	/*margin-right: 5px;*/
}

.interaction-container {
	padding: 70px 0 20px 0;
}
.interaction-inner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
}
.interaction-inner-container > button:not(:last-child) {
	margin-right: 20px;
}

.interaction-inner-container-split {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
}
.interaction-inner-container-split > div {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	flex: 1;
}

.interaction-inner-container-split > div:not(:last-child),
.interaction-inner-container-split > div > button:not(:last-child) {
	margin-right: 20px;
}

.item-label {
	margin-bottom: 5px;
	display: flex;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	color: #000000;
}

.navigation-message,
.interaction-loading-message {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.interaction-loading-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup-logo-area {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
}
.popup-title {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	line-height: normal;
	text-align: center;
}
.popup-content-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.popup-interaction-container {
	padding: 30px 20px 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}