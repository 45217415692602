.download-table-xls-button {
  width: 200px;
  height: 45px;
  margin-left: 25px;
  box-sizing: border-box;
  background: #FF6D1D;
  border: 2px solid #FFFFFF;
  border-radius: 6px;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  transition: background-color 0.3s, color 0.3s; /* Add smooth transition */
}

.download-table-xls-button:hover {
  background-color: #FFFFFF;
  color: #FF6D1D;
  border: 1px solid #FF6D1D;
}

/* Optional: Add active state styles */
.download-table-xls-button:active {
  background-color: #FF6D1D;
  color: #FFFFFF;
}

.hidden-excel-button {
  display: none;
}


