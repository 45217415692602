.loading-container {
  position: relative;
  min-height: 100px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.release-note-title {
  font-weight: 700;
  padding: 10px 0;
  line-height: normal;
}

.release-note-date {
  padding: 10px 0;
}

.release-note-content-container {
  padding: 10px 0;
}

.release-note-content-title {
  font-weight: 700;
  line-height: normal;
}
.release-note-content-description {
  line-height: normal;
  margin: 5px 0 15px 10px;
}

.release-note-inner-content-container {
  margin: 5px 0 15px 0;
  list-style-position: inside;
}

.release-note-inner-content-title {
  font-weight: 700;
  line-height: normal;
}

.release-note-inner-content-text {
  line-height: normal;
}

.new {
  padding: 3px 5px;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  line-height: normal;
}