.loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/src/assets/background_logo.svg);
}

.container {
  height: 100vh;
  width: 100vw;
  padding: 80px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url(/src/assets/background_logo.svg);
}

.pop-up-box {
  background-color: white;
  border-radius: 5px;
	box-shadow: 0px 3px 6px #00000029;
	padding: 40px 30px;
  position: relative;
}

.package-box {
  margin-right: 20px;
  max-width: 400px;
}

.payment-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 0px;
  min-width: 400px;
}

.payment-form {
  flex: 1;
}

.other-packages-container {
  margin: 30px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.other-packages-text {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.message-container {
  margin-bottom: 20px;
  padding: 20px 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  color: white;

}

.message-text {
  line-height: normal;
  font-weight: 700;
}

.interaction-container {
  margin-top: 20px;
  height:50px;
}

.loading-interaction-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}