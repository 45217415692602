.sidebutton_label {
  color: #2d2d2d;
  position: absolute;
  top: 56px;
  text-transform: lowercase;

  height: 13px;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
}

.sidebutton_label:first-letter {
  text-transform: uppercase;
}

.connect_logo {
  width: 25px !important;
  height: 25px !important;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #26303d;
}

.connect_logo:hover {
  color: #f26934;
}
