.loading-container {
  position: relative;
  min-height: 100px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  min-height: 100px;
  min-width: 250px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  margin-bottom: 20px;
}

.title-text {
  text-align: center;
  font-weight: 700;
}

.description-container {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.description-text {
  text-align: center;
}

.details-section {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.detail-item-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  padding: 4px 0;
}
.detail-item-container:not(:first-child) {
  border-top: none !important;

}

.line-container {
  padding: 30px 40px;
}
.line {
  width: 100%;
  display: block;
}

.price-subtotal-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.price-subtotal-text {
  font-weight: 700;
  line-height: normal;
}
.price-subtotal-text:not(:last-child) {
  margin-bottom: 5px;
}

.price-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-text {
  text-align: center;
  line-height: normal;
}

.secondary-price-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-price-text {
  text-align: center;
  line-height: normal;
}

.upgrade-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgrade-text {
  text-align: center;
  line-height: normal;
}

.promo-code-container{
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
}

.error-container{
  font-size: 12px;
  color: red;
  font-weight: 700;
  margin-left: 10px;
}
.expiry-date-container{
  font-size: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  
}