.container {
    position: relative;
    width: 100%;
    height: 225px; /* Adjust to fit the circle's height */
    overflow: hidden;
    
    
  }
  
.lineContainer {
    overflow-x: scroll;
    height: 100%;
    white-space: nowrap;
    overflow-y: hidden;
  }
.lineContainer::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari, Opera */
}

.lineContainer {
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}
  
  .line {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background: white;
    border: 4px solid white;
    border-radius: 99px;
    margin-left: 2px;
    min-width: 99.7%;
  }
  
  .circle {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: red;
    border-radius: 50%;
    /* margin-right: 100px; */
    position: relative;
    /* top: -15px; Half the height of the circle to position it on the line */
  }

  .circleContainer {
    height: auto;
    position: relative;
    top: -140px;
    margin-right:100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: "4",
  }
  
  .circleContainer:last-child {
    margin-right: 0;
  }
  

  .svg-container {
    display: inline-block;
    /* justify-content: center;
    align-items: center; */
    flex-direction: column;
    width: 24px;
    height: 100px;
    /* margin: 0 5px; Adjust the margin as needed */
    
  }
