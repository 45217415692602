.cell-wrap {
  white-space: pre-wrap;
  overflow: hidden;
}

.truncate {
  white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table {
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
}

.row-title-span {
  font-weight: 700;
}

.table > thead > tr > th {
  padding: 0 10px;
  font-weight: 400;
}

.table th {
  padding: 10px 0;
}

.table td {
  border: 1px solid #00000000;
  padding: 2px 5px;
}

.cell {
  vertical-align: middle;
}