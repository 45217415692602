.container {
	position: relative;
	width: 100%;
}

.page-title-container {
	width: 100%;
	height: 300px;
	padding: 50px 0 50px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.page-title-text {
	font-weight: 700;
	line-height: normal;
}

.page-title-container > button {
	margin-top: 50px !important;
}

.release-notes-inner-container {
	padding: 40px 70px;
}