.card-root {
	position: relative;
	margin-right: 0px;

	width: 200px;
	min-height: 145px;
	margin-bottom: 10px;
	border-radius: 8px;
	background-color: white;
}

.card-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	height: 100%;
	width: 100%;
	padding: 16px 20px 16px 20px;
}

.profile-image {
	display: flex;
}

.x-margin {
	margin: auto 0;
	margin-left: 96px;
	cursor: pointer;
}

.name {
	margin-top: 12px;
	display: flex;
	flex-direction: column;
	width: 100%;
	
}

.description {
	font-family: SF Pro Text;
	font-size: 11px;
	font-style: normal;
	font-weight: 300;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 20px;
}

.single-checkbox-container {
}

.personnel-checkbox-container{
 font-size: 13px;
}

.resume-button-container{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;	
}
.resume-button{
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid;
	border-radius: 6px;
	width: 70%;
	height: 70%;
}