.loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/src/assets/background_logo.svg);
}

.container {
  height: 100vh;
  width: 100vw;
  padding: 80px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url(/src/assets/background_logo.svg);
}