.container {
  padding: 24px 0 0 40px;
}
.title {
  font-weight: 700;
}

.element {
  margin-top: 40px;
}

.major-element-title {
  font-weight: 700;
}

.element-content {
  padding: 5px 0 0 20px;
}

.error-content {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.loading-content {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.major-element-description {
  margin-top: 10px;
}

.email-link {
  text-decoration: underline;
  cursor: pointer;
}

.concierge-id-input {
  font-size: 22px;
  padding: 10px 10px;
  border-radius: 10px;
  width: 520px;
}